// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrl: "https://api-staging.everon.fi",
  alertServerBaseUrl: "https://alertserver-staging.everon.net",
  onOnBaseUrl: "https://onon-dev.everon.net",
  videoServerBaseUrl: "https://video-staging.everon.net:5100",
  translationsUrl: "https://everonstorage.blob.core.windows.net/manuals/translations/",
  version: "2.4.2-rc"
};
